/* eslint-disable @typescript-eslint/no-explicit-any */
import gql from 'graphql-tag';
import {
  UploadStatus,
  UploadStage,
  UploadType,
  EWebinar,
  UploadStatusInput,
} from '$/GraphQL/schema/types';
import config from '$/config';
import { LOCAL_UPLOAD_DONE } from '$/GraphQL/queries/VimeoVideo';
import { UPDATE_EWEBINAR, UPDATE_UPLOAD_STATUS_FRAGMENT } from '$/GraphQL/queries/EWebinar';
import { logger } from '$/utils/logger';
import { ApolloClient } from '@apollo/client';

const fragment = gql`
  fragment vimeoUpload on EWebinar {
    __typename
    id
    vimeoVideoId

    uploadStatus {
      __typename
      id
      uuid
      stage
      type
      progress
      done
      error
      urls {
        name
        sourceUrl
        uploadUrl
        playUrl
        uri
        vimeoId
      }
    }
  }
`;

export interface BeginVimeoUploadInput {
  id: string;
  file: File;
  uploadStatus: UploadStatusInput;
}

export interface UpdateEwebinarLocalInput {
  id: string;
  uploadStatus: UploadStatus;
}

const updateWebinarUploadStatus = (
  client: ApolloClient<any>,
  ewebinarId: string,
  uploadStatus: UploadStatus
): void => {
  try {
    logger.debug('VIMEO UPLOAD', 'update uploadStatus', ewebinarId, uploadStatus);
    const fragment = client.readFragment({
      id: `EWebinar:${ewebinarId}`,
      fragment: UPDATE_UPLOAD_STATUS_FRAGMENT,
      fragmentName: 'UpdateUploadStatus',
    });
    logger.debug('VIMEO UPLOAD', 'uploadStatus fragment', fragment);

    client.writeFragment({
      id: `EWebinar:${ewebinarId}`,
      fragment: UPDATE_UPLOAD_STATUS_FRAGMENT,
      fragmentName: 'UpdateUploadStatus',
      data: {
        uploadStatus: {
          ...fragment?.uploadStatus,
          ...uploadStatus,
          urls: {
            ...fragment?.uploadStatus?.urls,
            ...uploadStatus.urls,
            __typename: 'VimeoUrls',
          },
          __typename: 'UploadStatus',
        },
        __typename: 'EWebinar',
      },
    });
  } catch (e) {
    const error = e as Error;
    logger.error(`update upload status fragment error : ${error.message}`, { error });
  }
};

const resolvers = {
  Mutation: {
    updateEwebinarLocal: async (
      _root: any,
      variables: UpdateEwebinarLocalInput,
      { cache, getCacheKey }: any
    ): Promise<void> => {
      const id = getCacheKey({
        __typename: 'EWebinar',
        id: variables.id,
      });

      const ewebinarData = cache.readFragment({ fragment, id });
      try {
        cache.writeFragment({
          id,
          fragment,
          data: {
            ...ewebinarData,
            uploadStatus: variables.uploadStatus,
          },
        });
      } catch (e) {
        const error = e as Error;
        logger.error(`updateEwebinarLocal : ${error.message}`, { error });
      }
    },
    beginVimeoUpload: async (
      _root: any,
      variables: BeginVimeoUploadInput,
      { cache, client, getCacheKey }: any
    ): Promise<boolean> => {
      // how frequently progress should be updated in cache
      // reducing this value below 3 seconds causes issue with polling
      const throttleDuration = 3000; // in milliseconds
      let lastTimestamp: number = new Date(Date.now()).getTime();

      // Prompt user before tab close
      window.onbeforeunload = (): boolean => {
        return true;
      };

      try {
        const { file, uploadStatus } = variables;
        const uuid = uploadStatus.uuid;

        const tus = await import('tus-js-client');

        const upload = new tus.Upload(file, {
          endpoint: `${config.VIMEO_BASE_URL}/me/videos`,
          uploadUrl: uploadStatus.urls!.uploadUrl,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          headers: {},
          onError: (error): void => {
            throw error;
          },
          onProgress: (bytesUploaded: number, bytesTotal: number): void => {
            // this is to add throttling
            // without throttling, polling doesn't work
            const newTimestamp = new Date(Date.now()).getTime();
            if (newTimestamp - lastTimestamp < throttleDuration) {
              // don't update the cache
              return;
            }

            // update timestamp
            lastTimestamp = newTimestamp;

            const id = getCacheKey({
              __typename: 'EWebinar',
              id: variables.id,
            });

            const ewebinarData: Partial<EWebinar> = cache.readFragment({
              fragment,
              id,
            });

            // cancel upload
            if (
              ewebinarData.uploadStatus?.uuid !== uuid ||
              (ewebinarData.uploadStatus?.stage &&
                [UploadStage.Canceled, UploadStage.Done, UploadStage.Closed].includes(
                  ewebinarData.uploadStatus.stage
                ))
            ) {
              upload.abort();
              return;
            }

            // progress
            const percentage = Math.round(
              (bytesUploaded / bytesTotal) * config.UPLOAD_PROGRESS_MAX
            );

            const uploadStatus: UploadStatus = {
              __typename: 'UploadStatus',
              ...ewebinarData.uploadStatus!,
              stage: UploadStage.Uploading,
              type: UploadType.Local,
              progress: percentage,
              done: false,
              error: null,
            };
            updateWebinarUploadStatus(client, variables.id, uploadStatus);
          },
          onSuccess: async (): Promise<void> => {
            // Remove navigation prompt
            window.onbeforeunload = null;
            await client.mutate({
              variables: {
                id: variables.id,
              },
              mutation: LOCAL_UPLOAD_DONE,
            });
          },
        });

        // start video upload
        upload.start();
      } catch (e) {
        const error = e as Error;
        logger.error(`Local upload error: ${error.message}`, { error });

        const id = getCacheKey({
          __typename: 'EWebinar',
          id: variables.id,
        });

        const { uploadStatus } = cache.readFragment({
          fragment,
          id,
        });

        const data = {
          ...uploadStatus,
          done: true,
          error: error.message,
          stage: UploadStage.Error,
        };

        await client.mutate({
          variables: {
            id: variables.id,
            uploadStatus: data,
          },
          mutation: UPDATE_EWEBINAR,
        });

        // Remove browser navigate away warning
        window.onbeforeunload = null;
      }

      return true;
    },
  },
};

export default resolvers;
