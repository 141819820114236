import gql from 'graphql-tag';

export const SET_SCRIPT_FRAGMENT = gql`
  fragment SetScript on SetScriptRelation {
    setId
    scriptId
    registrationPage
    registrationForm
    thankyouPage
    presentationRoom
  }
`;

export const SCRIPT_FRAGMENT = gql`
  fragment Script on Script {
    id
    uuid
    name
    content
    disableIfGdprDeclined
    usedOnPublishedCatalog
    usedInWebinars {
      ...SetScript
    }
    lastError
    lastErrorAt
    lastErrorPage
  }
  ${SET_SCRIPT_FRAGMENT}
`;

export const SCRIPT_IN_SET_FRAGMENT = gql`
  fragment ScriptInSet on ScriptInSet {
    setId
    scriptId
    registrationPage
    registrationForm
    thankyouPage
    presentationRoom
    script {
      ...Script
    }
  }
  ${SCRIPT_FRAGMENT}
`;

export const SCRIPT = gql`
  query script($id: String!) {
    script(id: $id) {
      ...Script
    }
  }
  ${SCRIPT_FRAGMENT}
`;

export const GET_SCRIPTS = gql`
  query scripts {
    scripts {
      ...Script
    }
  }
  ${SCRIPT_FRAGMENT}
`;

export const CREATE_SCRIPT = gql`
  mutation createScript($data: ScriptInput!) {
    createScript(data: $data) {
      ...Script
    }
  }
  ${SCRIPT_FRAGMENT}
`;

export const UPDATE_SCRIPT = gql`
  mutation updateScript($data: UpdateScriptInput!) {
    updateScript(data: $data) {
      ...Script
    }
  }
  ${SCRIPT_FRAGMENT}
`;

export const DELETE_SCRIPT = gql`
  mutation deleteScript($id: String!) {
    deleteScript(id: $id)
  }
`;

export const JOIN_EWEBINAR_WITH_SCRIPT = gql`
  mutation joinEWebinarWithScripts($data: UpdateEWebinarSetInput!) {
    joinEWebinarWithScripts(data: $data) {
      ...SetScript
    }
  }
  ${SET_SCRIPT_FRAGMENT}
`;
