import * as Yup from 'yup';
import { PATTERN_REGEX_EMAIL } from './email-regex';

const YUP_EMAIL_METHOD = 'email';
const MESSAGE = `This must be a valid email address`;

Yup.addMethod(Yup.string, YUP_EMAIL_METHOD, function yupEmail(message?: string) {
  return this.test(YUP_EMAIL_METHOD, MESSAGE, function (email) {
    if (email && !PATTERN_REGEX_EMAIL.test(String(email).toLowerCase())) {
      return this.createError({
        path: this.path,
        message: message ?? MESSAGE,
      });
    }
    return true;
  });
});
