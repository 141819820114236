/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import config from '../config';
import { CWLogger } from './cwLogger';
import type { EventPerfDetails } from './logPerfHelper';

declare global {
  interface Window {
    __ewLogAreas?: string[]; // Enabled debug area (eg. window.__ewLogAreas = ['CHAT'])
  }
}

const disabledAreas: string[] = ['SESSIONS', 'JIT', 'PLAY', 'CHAT', 'ANIM', 'RESIZE'];
const cloudLoggedAreas: string[] = ['PLAY'];

let cwLogger: CWLogger | null = null;

const installCwLogger = async () => {
  try {
    cwLogger = new CWLogger();
  } catch (e) {
    cwLogger = null;
    console.error('Error setting up cloudwatch logger', e);
  }
};

// Flip to !config.isLocal if not testing
if (!config.isSSR && !config.isLocal) {
  void installCwLogger();
}

type LoggerFunc = (message: string, ...args: any[]) => Promise<void> | undefined;
type DebugLoggerFunc = (area: string, message: string, ...args: any[]) => Promise<void> | undefined;
type LoggerFuncPerf = (perfDetails: EventPerfDetails) => void;

export const logger = (function (): {
  sendLogs: () => Promise<void>;
  debug: DebugLoggerFunc;
  info: LoggerFunc;
  warn: LoggerFunc;
  error: LoggerFunc;
  perf: LoggerFuncPerf;
} {
  return {
    sendLogs: () => {
      return cwLogger?.onInterval() ?? Promise.resolve();
    },
    debug: (area: string, message: string, ...args: any[]): Promise<void> | undefined => {
      let allowedAreas: string[] = [];
      if (typeof window !== 'undefined' && Array.isArray(window?.__ewLogAreas)) {
        allowedAreas = window?.__ewLogAreas;
      }

      let promise = undefined;
      if (cwLogger && cloudLoggedAreas.includes(area)) {
        promise = cwLogger.log(new Error(message), { type: 'debug', args });
      }

      if (disabledAreas.includes(area) && !allowedAreas.includes(area)) {
        return promise;
      }
      console.debug(`@${area} `, message, ...args);
      return promise;
    },
    info: (message: string, ...args: any[]): Promise<void> | undefined => {
      const type = 'INFO';
      console.info(' ', message, ...args);
      return cwLogger?.log(new Error(message), { type, args });
    },
    warn: (message: string, ...args: any[]): Promise<void> | undefined => {
      const type = 'WARN';
      console.warn(type, ' ', message, ...args);
      return cwLogger?.log(new Error(message), { type, args });
    },
    error: (message: string, ...args: any[]): Promise<void> | undefined => {
      const type = 'ERROR';
      console.error(type, ' ', message, ...args);
      return cwLogger?.log(new Error(message), { type, args });
    },
    perf: (perfDetailsEnd: EventPerfDetails): Promise<void> | undefined => {
      if (perfDetailsEnd.durationMs === 0) {
        return;
      }
      const message = `PERF  ${perfDetailsEnd.type!}.${perfDetailsEnd.name!} = ${perfDetailsEnd.durationMs!}ms`;
      console.debug(message, perfDetailsEnd);
      return cwLogger?.log(new Error(message), perfDetailsEnd);
    },
  };
})();
