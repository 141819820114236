import Cookies from 'js-cookie';
import config from '../config';

const tokenName = (name: string): string => {
  if (config.isProduction) {
    return name;
  }
  return `${name}-${config.env}`;
};

export const removeVisitorId = (): void => {
  try {
    localStorage.removeItem(tokenName('eWebinar-vid'));
    Cookies.remove(tokenName('XSRF-TOKEN'));
  } catch {
    // Do nothing
  }
};

export const setVisitorId = (visitorId?: string): void => {
  if (!visitorId) {
    return;
  }

  // use localStorage instead of cookie due to Cross Domain Cookie issue
  try {
    localStorage.setItem(tokenName('eWebinar-vid'), visitorId);
  } catch {
    // Do nothing
  }
};

export const getVisitorId = (): string | null => {
  try {
    // LEGACY
    let visitorId: string | null = localStorage.getItem(tokenName('eWidget-visitorId'));
    if (visitorId) {
      localStorage.removeItem(tokenName('eWidget-visitorId'));
      setVisitorId(visitorId);
      return visitorId;
    }

    visitorId = localStorage.getItem(tokenName('eWebinar-vid'));
    if (visitorId) {
      return visitorId;
    }

    visitorId = Cookies.get(tokenName('eWebinar-vid')) ?? null;

    return null;
  } catch (error) {
    return null;
  }
};
