import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { BlockUnsupportedBrowsers } from '$/sections/PublishedWebinar/ViewWebinar/BlockUnsupportedBrowsers';

import { apolloClient } from './ApolloClient';

require('$/utils/yup-email');

const BackendConnection: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <ApolloProvider client={apolloClient}>
      <BlockUnsupportedBrowsers />
      {children}
    </ApolloProvider>
  );
};

export default BackendConnection;
