import gql from 'graphql-tag';

export const NOTIFICATION_FRAGMENT = gql`
  fragment Notification on Notification {
    id
    type
    sendBy
    sendTo
    skipWhenConverted
    whenNumber
    whenUnit
    subject
    followUpTo
    message
    previewText
    fontSize
  }
`;

export const GET_EWEBINAR_NOTIFICATIONS = gql`
  query getEwebinarNotifications($ewebinarId: String!) {
    notifications(ewebinarId: $ewebinarId) {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const GET_EWEBINAR_NOTIFICATIONS_FROM_TEMPLATE = gql`
  query getEwebinarNotificationsFromTemplate($setId: String!) {
    ewebinarTemplateFrom(setId: $setId) {
      notifications {
        ...Notification
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const CREATE_EWEBINAR_NOTIFICATION = gql`
  mutation createNotification($data: NotificationInput!) {
    createNotification(data: $data) {
      id
      notifications {
        ...Notification
      }
    }
  }

  mutation deleteNotification($id: String!) {
    deleteNotification(id: $id) {
      id
      notifications {
        ...Notification
      }
    }
  }

  mutation duplicateNotification($id: String!) {
    duplicateNotification(id: $id) {
      id
      notifications {
        ...Notification
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const UPDATE_EWEBINAR_NOTIFICATION = gql`
  mutation updateNotification($data: NotificationInput!) {
    updateNotification(data: $data) {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const SEND_EWEBINAR_NOTIFICATION_TEST = gql`
  mutation sendNotificationTest($id: String!) {
    sendNotificationTest(id: $id)
  }
`;

export const GET_NOTIFICATION_FOOTER = gql`
  query getNotificationHeaderAndFooter($type: NotificationType!, $ewebinarId: String!) {
    generateHeaderAndFooter(type: $type, ewebinarId: $ewebinarId) {
      header
      footer
    }
  }
`;
