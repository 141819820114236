import gql from 'graphql-tag';

const typeDefs = gql`
  input File {
    name: String
    size: Int
    type: String
  }

  input VimeoUrlsInput {
    vimeoId: Float
    name: String!
    uri: String!
    sourceUrl: String
    playUrl: String!
    uploadUrl: String
    thumbnailUrl: String
  }

  # Upload type
  enum UploadType {
    Local
    VimeoPull
    WorkerTus
  }

  # Upload status stage
  enum UploadStage {
    Transcoding
    Uploading
    Canceled
    None
    Error
    Done
    Waiting
    Closed
  }

  input UploadStatusInput {
    uuid: String
    stage: UploadStage!
    type: UploadType!
    progress: Float!
    done: Boolean!
    error: String
    urls: VimeoUrlsInput!
  }

  extend type Mutation {
    beginVimeoUpload(id: String!, file: File!, uploadStatus: UploadStausInput!): Boolean
  }
`;

export default typeDefs;
