import gql from 'graphql-tag';

export const THEME_SETTINGS_FRAGMENT = gql`
  fragment ThemeSettings on ThemeSettings {
    primaryColor
    actionColor
    font
    fontSize
    fontCssUrl
    borderRadius
  }
`;
