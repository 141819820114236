import gql from 'graphql-tag';

import { UPLOAD_STATUS_FRAGMENT, EWEBINAR_FRAGMENT } from './PublicEWebinarFragment';

export const VIMEO_TEXT_TRACK_URLS = gql`
  fragment VimeoTextTrackUrls on VimeoTextTrackUrls {
    id
    name
    active
    language
    type
    uri
    uploadUrl
  }
`;

export const LOCAL_UPLOAD_DONE = gql`
  mutation setLocalVimeoUploadDone($id: String!) {
    setLocalVimeoUploadDone(id: $id) {
      id
      title
      vimeoVideoId
      thumbnail
      uploadStatus {
        ...UploadStatus
      }
    }
  }
  ${UPLOAD_STATUS_FRAGMENT}
`;

export const TRANSFER_VIDEO = gql`
  mutation uploadVideo($data: UploadVideoInput!) {
    uploadVideo(data: $data)
  }
`;

export const CANCEL_VIMEO_VIDEO_UPLOAD = gql`
  mutation cancelVimeoVideoUpload($id: String!) {
    cancelVimeoVideoUpload(id: $id) {
      ...EWebinar
    }
  }
  ${EWEBINAR_FRAGMENT}
`;

export const CREATE_TEXT_TRACK_UPLOAD_URL = gql`
  mutation createVimeoTextTrackUploadUrl($data: TextTrackUploadInput!) {
    createVimeoTextTrackUploadUrl(data: $data) {
      ...VimeoTextTrackUrls
    }
  }
  ${VIMEO_TEXT_TRACK_URLS}
`;

export const DELETE_VIMEO_TEXT_TRACK = gql`
  mutation deleteVimeoTextTrack($ewebinarId: String!, $vimeoTextTrackId: Float!) {
    deleteVimeoTextTrack(ewebinarId: $ewebinarId, vimeoTextTrackId: $vimeoTextTrackId) {
      ...EWebinar
    }
  }
  ${EWEBINAR_FRAGMENT}
`;
