import gql from 'graphql-tag';

export const ACTION_PROPERTY_DESCRIPTION_FRAGMENT = gql`
  fragment ActionPropertyDescription on ActionPropertyDescription {
    group
    property
    parentProperty
    alias
    entityPath
    name
    index
    description
    type
    source
    values
    excludeActions
    inWebinars {
      setId
      interactionId
      inDraft
      inPublished
    }
  }
`;

export const ACTION_PROPERTY_VALUE_FRAGMENT = gql`
  fragment ActionPropertyValue on ActionPropertyWithValue {
    property
    parentProperty
    alias
    entityPath
    name
    index
    description
    type
    source
    values
    excludeActions
    value
  }
`;

export const ATTENDEE_PROPERTIES_FRAGMENT = gql`
  fragment AttendeeActionProperties on AttendeeActionProperties {
    conditionalDisplay {
      ...ActionPropertyValue
    }
    mentionField {
      ...ActionPropertyValue
    }
  }
  ${ACTION_PROPERTY_VALUE_FRAGMENT}
`;
