import gql from 'graphql-tag';

export const MEDIAREF = gql`
  fragment MediaRef on MediaRef {
    url
    imageUrl
    videoUrl
    width
    height
    alt
    crop
    fit
  }
`;

export const EXTRACT_COLORS = gql`
  query extractAssetColors($src: String!) {
    extractAssetColors(src: $src) {
      id
      colors
    }
  }
`;

export const CREATE_UPLOAD_URL = gql`
  mutation createUploadUrl($data: UploadAssetInput!) {
    createUploadUrl(data: $data) {
      url
      uploadUrl
    }
  }
`;

export const SCRAPE_META_FROM_URL = gql`
  mutation getMediaRefFromURL($url: String!) {
    getMediaRefFromURL(url: $url) {
      ...MediaRef
    }
  }
  ${MEDIAREF}
`;
export const UPLOAD_DRAFT_MEDIA = gql`
  mutation uploadDraftMedia($data: MetaMediaInput!) {
    uploadDraftMedia(data: $data) {
      ...MediaRef
    }
  }
  ${MEDIAREF}
`;
