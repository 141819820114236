import StackTrace from 'stacktrace-js';
import { compressToUTF16 } from 'lz-string';

import config from '../config';
import { getVisitorId } from './visitor';
import { httpRequest } from '../widget/src/utils/httpRequest';

type CWMessage = Record<string, unknown>;

export class CWLogger {
  private messages: CWMessage[] = [];

  constructor() {
    setInterval(() => {
      this.onInterval();
    }, this.interval);
  }

  private get interval(): number {
    return 10000;
  }

  private getLogStreamName(): string {
    return getVisitorId() ?? 'anonymous';
  }

  private async messageFormatter({
    e,
    info,
  }: {
    e: Error;
    info: Record<string, unknown>;
  }): Promise<CWMessage | null> {
    if (!e.message) {
      return null;
    }

    let stack = null;
    if (e.stack) {
      stack = e.stack;
      try {
        stack = await StackTrace.fromError(e, { offline: true });
      } catch {
        // Do nothing
      }
    }

    return {
      message: e.message,
      visitorId: getVisitorId(),
      timestamp: new Date().getTime(),
      userAgent: typeof window === 'undefined' ? undefined : window.navigator.userAgent,
      stack,
      ...info,
    };
  }

  public async log(e: Error, info: Record<string, unknown> = { type: 'unknown' }): Promise<void> {
    const message = await this.messageFormatter({ e, info });
    if (!message) return;
    this.messages.push(message);
  }

  private async doSendMessages(messages: CWMessage[]): Promise<void> {
    try {
      const promise = new Promise<string>((resolve, reject) => {
        httpRequest({
          url: config.CW_LOGGER_URL,
          method: 'POST',
          body: {
            env: config.env,
            logStreamName: this.getLogStreamName(),
            messages: compressToUTF16(JSON.stringify(messages)),
          },
          postJSON: true,
          callback: resolve,
          onError: (error, status) => {
            if (status === 400) {
              // something wrong with the request, don't retry
              console.error('Failed to send logs to cloudwatch', {
                error,
                messages,
              });
              resolve('Failed to send logs to cloudwatch');
              return;
            }
            reject(new Error(error ?? 'Failed to send logs to cloudwatch'));
          },
        });
      });
      await promise;
    } catch (e) {
      console.error('Error sending log to cloudwatch', e);
      throw e;
    }
  }

  public async onInterval(): Promise<void> {
    // if there's no internet connection, don't send logs
    if (!navigator.onLine) return;
    const messages = this.messages.splice(0);
    if (messages.length === 0) return;
    try {
      await this.doSendMessages(messages);
    } catch (e) {
      console.error('Error sending logs to cloudwatch', e);
      this.messages.push(...messages); // put back the messages
    }
  }
}
